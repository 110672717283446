<template>
  <div class="modal fade show" id="microphonePermissionModal" tabindex="-1" aria-labelledby="microphonePermissionModalLabel" aria-modal="true" role="dialog" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <p class="lightblueTxt">{{ $t('common.request_microphone') }}</p>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-6">
              <button type="button" class="btn btn-secondary" @click="denyPermission">{{ $t('common.deny') }}</button>
            </div>
            <div class="col-6">  
              <button type="button" class="btn btn-primary" @click="grantPermission">{{ $t('common.allow') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // Emit event when the user grants permission
    grantPermission() {
      this.$emit('permissionGranted');
    },

    // Emit event when the user denies permission
    denyPermission() {
      this.$emit('permissionDenied');
    },
  },
};
</script>