<template>
	<section class="section2 text-center">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<img src="images/alert-visual.png" alt="Alert Visual" class="mx-auto d-block img-fluid"/>
					<h5 class="blueTxt mb-5">{{ $t('instructions.step1.go_to_a_quiet_place') }}</h5>
					<router-link class="btn blueBtn" to="/prompt">{{ $t('instructions.step1.instructions') }}</router-link>
					<router-link class="btn blueBtn" to="/main">{{ $t('instructions.step1.go_to_assessment') }}</router-link>
					<!-- <button class="btn blueBtn" @click="nextButtonEvent()">{{ buttonText }}</button> -->
				</div>
			</div>
		</div>
		<!-- Include the custom microphone permission modal component -->
		<MicrophonePermissionModal
		v-if="showMicrophonePermissionModal"
		@close="closeMicrophonePermissionModal"
		@permissionGranted="handleMicrophonePermissionGranted"
		@permissionDenied="handleMicrophonePermissionDenied"
		></MicrophonePermissionModal>
		<permissionDenied />
	</section>
</template>
<script>
import { mapGetters } from 'vuex';
import MicrophonePermissionModal from '~/components/MicrophonePermissionModal.vue';
import permissionDenied from '~/components/extra/permissionDenied.vue';

export default {
	components: {
		MicrophonePermissionModal,
		permissionDenied
	},
	computed: {
		...mapGetters({
			stream: 'inhaler/stream',
			testFor: 'inhaler/getTestingFor',
		}),
		buttonText() {
			return this.testFor !== null ? "Skip" : "Next";
		},
	},
	data() {
		return {
			showMicrophonePermissionModal: false,
		};
	},
	methods: {
		openMicrophonePermissionModal() {
			if(this.stream == null) {
				this.showMicrophonePermissionModal = true;
			} else {
				this.showMicrophonePermissionModal = false;
			} // Show the modal when the component is mounted
		},
		// Close the microphone permission modal
		closeMicrophonePermissionModal() {
			this.showMicrophonePermissionModal = false;
		},
		// Handle microphone permission granted
		async handleMicrophonePermissionGranted() {
			await this.$store.dispatch('inhaler/fetchAudioStream');
			window.location.reload(true);
			await this.$store.dispatch('inhaler/fetchAudioStream')
		},
		// Handle microphone permission denied
		handleMicrophonePermissionDenied() {
			this.showMicrophonePermissionModal = false;
		},
		nextButtonEvent() {
			if(this.stream == null) {
				this.openMicrophonePermissionModal();
			} else {
				if(this.testFor !== null) {
					this.$router.push('/instructions/step-2');
				} else {
					this.$router.push('/prompt');
				}
			}
		},
	},
	mounted() {
		this.openMicrophonePermissionModal();
	},
};
</script>